import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useBreadcrumbs } from '@/../context/breadcrumbsContext';

const defaultMeta = {
    title: process.env.NEXT_PUBLIC_PAGE_NAME,
    siteName: process.env.NEXT_PUBLIC_PAGE_NAME,
    description: process.env.NEXT_PUBLIC_PAGE_DESCRIPTION,
    url: process.env.NEXT_PUBLIC_CANNONICAL_URL,
    type: 'website',
    robots: 'follow, index',
    image: ''
};

type SeoProps = {
    date?: string;
    templateTitle?: string;
    templateDescription?: string;
    hideBreadCrumbs?: boolean;
    skipSetBreadcrumbTitle?: boolean;
} & Partial<typeof defaultMeta>;

export default function Seo(props: SeoProps) {
    const { date, templateTitle, templateDescription, hideBreadCrumbs, skipSetBreadcrumbTitle } =
        props;
    const intl = useIntl();
    const router = useRouter();
    const breadCrumbs = useBreadcrumbs();
    const meta = useMemo(() => ({ ...defaultMeta, ...props }), [props]);
    const [pageTitle, setPageTitle] = useState(meta.title);
    const pageSiteName = useMemo(
        () =>
            intl.formatMessage({ id: 'page.title' }) !== 'page.title'
                ? intl.formatMessage({ id: 'page.title' })
                : meta.siteName || 'Betterstyle',
        [intl, meta.siteName]
    );
    const pageDescription =
        intl.formatMessage({ id: 'page.description' }) !== 'page.description'
            ? intl.formatMessage({ id: 'page.description' })
            : meta.description || 'Welcome to Betterstyle';

    const obj = useMemo(
        () => ({
            router,
            breadCrumbs,
            date,
            templateTitle,
            hideBreadCrumbs
        }),
        [router, breadCrumbs, date, templateTitle, hideBreadCrumbs]
    );

    const detect404 = useCallback(
        (page404: boolean) => {
            breadCrumbs.hideBreadCrumbs(page404);
        },
        [breadCrumbs]
    );

    useEffect(() => {
        detect404(obj.hideBreadCrumbs || false);

        const routeChangeCompleteHandler = () => {
            if (skipSetBreadcrumbTitle) {
                return;
            }
            obj.breadCrumbs.setTitle(obj.templateTitle || (meta.title as string));
        };

        obj.router.events.on('routeChangeComplete', routeChangeCompleteHandler);

        obj.breadCrumbs.setTitle(obj.templateTitle || (meta.title as string));

        return () => {
            obj.router.events.off('routeChangeComplete', routeChangeCompleteHandler);
        };
    }, [skipSetBreadcrumbTitle, obj, meta, detect404]);

    useEffect(() => {
        setPageTitle(
            templateTitle
                ? `${templateTitle} | ${pageSiteName}`
                : meta.title === pageSiteName
                  ? pageSiteName
                  : `${meta.title} | ${pageSiteName}`
        );
    }, [templateTitle, meta.title, pageSiteName]);

    meta.description = templateDescription || pageDescription;
    meta.image = props.image || `${defaultMeta.url}${router.basePath}/images/og.png`;

    return (
        <Head>
            <title>{pageTitle}</title>
            <meta name="robots" content={meta.robots} />
            <meta content={meta.description} name="description" />
            <meta property="og:url" content={`${meta.url}${router.asPath}`} />
            <link rel="canonical" href={`${meta.url}${router.asPath}`} />

            {/* Open Graph */}
            <meta property="og:type" content={meta.type} />
            <meta property="og:site_name" content={meta.siteName} />
            <meta property="og:description" content={meta.description} />
            <meta property="og:title" content={pageTitle} />
            <meta name="image" property="og:image" content={meta.image} />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={meta.description} />
            <meta name="twitter:image" content={meta.image} />

            {/*Google Search Console Verification*/}
            <meta
                name="google-site-verification"
                content="eDbcPD_OKfqKJKwX8kor3WThq2VasWXVD8OdfJXvzao"
            />

            {meta.date && (
                <>
                    <meta property="article:published_time" content={meta.date} />
                    <meta name="publish_date" property="og:publish_date" content={meta.date} />
                    <meta name="author" property="article:author" content="Beafeater" />
                </>
            )}

            {/* Favicons */}
            {favicons.map((linkProps) => (
                <link key={linkProps.href} {...linkProps} href={router.basePath + linkProps.href} />
            ))}
            <meta name="msapplication-TileColor" content="#0085CA" />
            <meta
                name="msapplication-TileImage"
                content={router.basePath + '/favicon/ms-icon-144x144.png'}
            />
            <link rel="icon" type="image/x-icon" href="/favicon/favicon.ico"></link>
            <meta name="theme-color" content="#0085CA" />
        </Head>
    );
}

const favicons: Array<React.ComponentPropsWithoutRef<'link'>> = [
    {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/favicon/apple-icon-57x57.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/favicon/apple-icon-60x60.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/favicon/apple-icon-72x72.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/favicon/apple-icon-76x76.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: '/favicon/apple-icon-114x114.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: '/favicon/apple-icon-120x120.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: '/favicon/apple-icon-144x144.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: '/favicon/apple-icon-152x152.png'
    },
    {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/favicon/apple-icon-180x180.png'
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: '/favicon/favicon-32x32.png'
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: '/favicon/favicon-96x96.png'
    },
    {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: '/favicon/favicon-16x16.png'
    },
    {
        rel: 'manifest',
        crossOrigin: 'use-credentials',
        href: '/favicon/manifest.json'
    }
];
